import { post } from './http';

async function token(idToken) {
  const res = await post('/api/clerk/token', {}, { token: idToken });
  return res.data.token;
}

export default {
  token,
};
